<template>
  <div  class="wrap">
    <!-- 小赚一笔 -->
    <div class="box-con">
      <el-form class="select btns params-wrap" size="small" inline :model="queryParams" label-width="100px">
            <el-form-item>
               <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
            </el-form-item>
            <el-form-item label="任务类型:">
              <el-select v-model="queryParams.taskType" @change="refresh"  clearable filterable placeholder="任务类型" style="width:260px">
                <el-option label="全部"  value=""></el-option>
                <el-option label="外卖" :value="2"  ></el-option>
                <el-option label="快递" :value="1" ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="执行状态:">
              <el-select v-model="queryParams.status" @change="refresh"  clearable filterable placeholder="执行状态" style="width:260px">
                <el-option label="全部"  value=""></el-option>
                <el-option label="待接单"  value= 0  ></el-option>
                <el-option label="进行中" :value="1" ></el-option>
                <el-option label="待确定" :value="2" ></el-option>
                <el-option label="已完成" :value="3" ></el-option>
                <el-option label="已取消" :value="4" ></el-option>
              </el-select>
            </el-form-item>
      </el-form>
      <!-- 列表 -->
      <div v-if="littleMoneyTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <paged-list v-model="queryParams" :data="littleMoneyList" :total="littleMoneyTotal"  :span="12">
         <template slot-scope="scope">
          <div class="list " @click="fetchLittleMoneyLoad(scope.row.id)">
            <div class="top cfx">
              <div class="left fl">
                <div class="new-title fl">
                  <p v-if='scope.row.taskType == 1'>快递</p>
                  <p v-if='scope.row.taskType == 2'>外卖</p>
                  <div class="txt">类型</div>
                </div>
                <div class="name fl">
                  <p>￥{{scope.row.money?scope.row.money:0}}</p>
                  <div class="txt">任务奖金</div>
                </div>
                <div class="name fl" v-if="scope.row.status == 0">
                  <p ><last-time :endTime="scope.row.deadline"></last-time></p>
                  <div class="txt">剩余时间</div>
                </div>
                <div class="name fl" v-if="scope.row.status !=0  ">
                  <p >{{scope.row.receiveTime?scope.row.receiveTime:'-'}}</p>
                  <div class="txt">接单时间</div>
                </div>
              </div>
              <div class="right fr cfx" v-if="scope.row.extend">
                <div class="name fl cfx">
                  <p>{{scope.row.extend.userName}}</p>
                  <div class="txt ">发布人</div>
                </div>
                <div class="name fl cfx">
                  <p>{{scope.row.extend.receiveUserId?scope.row.extend.receiveUserId:'-'}}</p>
                  <div class="txt ">接单人</div>
                </div>
                <div class="status fr cfx">
                  <p v-if="scope.row.status == 0">待接单</p>
                  <p v-if="scope.row.status == 1 " class="success">进行中</p>
                  <p v-if="scope.row.status == 2 " class="success">待确定</p>
                  <p v-if=" scope.row.status == 3" class="gray">已完成</p>
                  <p v-if="scope.row.status == 4" class="gray">已取消</p>
                  <p v-if="scope.row.status == 5" class="red">已删除</p>
                  <div class="txt fr">状态</div>
                </div>
              </div>
            </div>
            <div class="content cfx ">
              <el-row :gutter="20">
                  <el-col :span="12" >
                        <div class="time"><span>发布时间</span><span class="con">{{scope.row.time}}</span></div>
                  </el-col>
                  <el-col :span="12" >
                        <div class="time"><span>手机号码</span><span class="con">{{scope.row.phone}}</span></div>
                  </el-col>
                  <el-col :span="12" style="margin-top:20px;" v-if="scope.row.extend">
                        <div class="time"><span>学校名称</span><span class="con">{{scope.row.extend.schoolName}}</span></div>
                  </el-col>
                  <el-col :span="12" style="margin-top:20px;" v-if=" scope.row.status == 3">
                        <div class="time"><span>完成时间</span><span class="con">{{scope.row.finishTime}}</span></div>
                  </el-col>
              </el-row>
            </div>
          </div>
        </template>
        <template slot="control">
          <el-button type="primary" icon="el-icon-edit" circle  @click="drawer = true"></el-button>
          <!-- <el-button type="danger" icon="el-icon-delete" circle > </el-button> -->
        </template>
      </paged-list>
    </div>
    <!-- 详情 -->
    <el-drawer
      title="详情"
      :visible.sync="drawer"
      size="40%"
      >
        <div class="drawer " >
          <div class="title cfx">
              <div class="new-title fl">
                <p v-if='details.taskType == 2'>外卖</p>
                <p v-if='details.taskType == 1'>快递</p>
                <div class="txt">类型</div>
              </div>
              <div class="name-time fl">
                <p>{{details.time}}</p>
                <div class="txt">发布时间</div>
              </div>
              <div class="name fl">
                <p>￥{{details.money?details.money:0}}</p>
                <div class="txt">任务奖金</div>
              </div>
              <div class="name-time fl" v-if=" details.status == 0">
                <p ><last-time style="font-size:20px;" :endTime="details.deadline"></last-time></p>
                <div class="txt">剩余时间</div>
              </div>
              <div class="name-time fl" v-if=" details.status == 3">
                <p>{{details.finishTime}}</p>
                <div class="txt">完成时间</div>
              </div>
              <div class="status fr cfx">
                <p v-if=" details.status == 0">待接单</p>
                <p v-if=" details.status == 1" class="success">进行中</p>
                <p v-if=" details.status == 2" class="success">待确定</p>
                <p v-if=" details.status == 3" class="gray">已完成</p>
                <p v-if=" details.status == 4" class="gray">已取消</p>
                <p v-if=" details.status == 5" class="red">已删除</p>
                <div class="txt fr">状态</div>
              </div>
          </div>
          <div class="content cfx ">
            <div class="puplish">
              <p class="puplish-name">发布人</p>
              <div class="puplish-con cfx" v-if="createUser.extend">
                <div class="img fl">
                  <img :src="createUser.headImg" alt="">
                </div>
                <div class="name1 fl" >
                  <p>{{createUser.extend.userName}} 
                    <img v-if="createUser.sex == 1" src="../../assets/images/he/operate/man.svg" alt="">
                    <img v-if="createUser.sex == 0" src="../../assets/images/he/operate/woman.svg" alt="">
                  </p>
                  <div class="txt">{{createUser.extend.schoolName}}<span class="line">|</span>{{createUser.fade}}</div>
                </div>
                <div class="name fl">
                  <p>{{createUser.extend.mobile}}</p>
                  <div class="txt">手机号码</div>
                </div>
                <div class="name fl">
                  <p>{{createUser.extend.schoolName}}</p>
                  <div class="txt">学校名称</div>
                </div>
              </div>
              <!-- 取消原因和时间 -->
              <div class="call-off" v-if="details.cancelUserId != details.receiveId && details.status == 4 ">
                <div class="time ">
                  <span class="time-box">取消时间</span><span>{{details.finishTime}}</span>
                </div>
                <div class="cause cfx">
                  <div class="cause-box fl">取消原因</div><div class="cause-txt fl">{{details.cancelReason}}</div>
                </div>
              </div>
              <div class="path-box">
                <p>配送信息</p>
                <div class="path-con cfx">
                  <div class="icon-left fl">
                    <div class="green"></div>
                    <div class="dian"><img src="../../assets/images/he/operate/peisong_icon.svg" alt=""></div>
                    <div class="red"></div>
                  </div>
                  <div class="path-right fl">
                    <div class="start">{{details.startAddress}}</div>
                    <div class="finish">{{details.endAddress}}</div>
                  </div>
                </div>
              </div>
              <div class="status-con">
                <p>任务详情</p>
                <div class="text">{{details.content}}</div>
              </div>
              <!-- 进行中-接单详情 -->
              <div class="order-load" v-if="details.status != 0 ">
                <p class="puplish-name">接单信息</p>
                <div class="puplish-con cfx">
                  <div class="img fl">
                    <img  :src="sendUser.headImg" alt="">
                  </div>
                  <div class="order-box fl cfx" v-if="sendUser.extend">
                    <div class="name1 fl" >
                      <p>{{sendUser.extend.userName}}
                        <img v-if="sendUser.sex == 1" src="../../assets/images/he/operate/man.svg" alt="">
                        <img v-if="sendUser.sex == 0" src="../../assets/images/he/operate/woman.svg" alt="">
                      </p>
                      <div class="txt">{{sendUser.extend.schoolName?sendUser.extend.schoolName:'-'}}<span class="line">|</span>{{sendUser.fade}}</div>
                    </div>
                    <div class="name fl">
                      <p>{{sendUser.extend.mobile}}</p>
                      <div class="txt">手机号码</div>
                    </div>
                    <div class="name fl">
                      <p>{{sendUser.extend.schoolName?sendUser.extend.schoolName:'-'}}</p>
                      <div class="txt">学校名称</div>
                    </div>
                    <div class="path-ing cfx">
                      <div class="time fl">
                        <span class="time-tex">接单时间</span><span>{{details.receiveTime?details.receiveTime:'-'}}</span>
                      </div>
                      <div class="time fl" v-if="details.status == 2 || details.status == 3">
                        <span class="time-tex">完成时间</span><span>{{details.sendTime}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 取消订单原因和时间 -->
              <div class="call-off" v-if="details.cancelUserId == details.receiveId && details.status == 4" >
                <div class="time ">
                  <span class="time-box">取消时间</span><span>{{details.finishTime}}</span>
                </div>
                <div class="cause cfx">
                  <div class="cause-box fl">取消原因</div><div class="cause-txt fl">{{details.cancelReason}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import PagedList from "@/components/common/PagedList";
import lastTime from "@/components/common/lastTime";
import { mapActions, mapState } from "vuex";
import AreaTree from "@/components/common/AreaTree";
import pageMixin from "@/mixins/pageMixin";
export default {
  name:'noticeManagement',
  components: {
    PagedList,
    lastTime,
    AreaTree
  },
  mixins: [pageMixin],
  data() {
    return {
      drawer:false,
      queryParams:{
      },
      details:{},
      sendUser:{},
      createUser:{},
      pageApi: "getLittleMoneyList",
      loadApi: "getLittleMoneyLoad",
    };
    
  },
  computed: {
    ...mapState("littleMoney", ["littleMoneyList", "littleMoneyTotal"]),
  },
  methods: {
     ...mapActions("littleMoney", [
      "getLittleMoneyList",
      "getLittleMoneyLoad",
    ]),
    // 详情
    async fetchLittleMoneyLoad(id){
      this.drawer = true
      const res = await this.getLittleMoneyLoad({id})
      this.details = res.task
      this.sendUser = res.sendUser
      this.createUser = res.createUser
      // this.detailImg = res.data.market.pics.splic(',')
      console.log(res.createUser,'发布人');
      console.log(res.sendUser,'接收人');
      console.log(res.task,'详情');
    },
    handleQuery(data) {
        this.clearArea(false)
        data.forEach(ele => {
          if ((ele + "").length === 9) { this.queryParams.province = ele }
          if ((ele + "").length === 12) { this.queryParams.city = ele }
          if ((ele + "").length === 15) { this.queryParams.area = ele }
          if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
        });
        this.refresh();
      },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if(isRefresh) this.refresh();
    }
  },
  async mounted() {
    this.refresh();
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
*{font-family: PingFangSC-Regular, PingFang SC;}
.wrap{
  width: 100%; height: 100%;
  .box-con{ width: 100%; min-height: 100%; background:#fff;padding: 20px;
    .select{
      .year {
        /deep/.el-input__inner {  
          background: url('../../assets/images/he/operate/area.svg') no-repeat; 
          background-size: 12px 14px;  
          background-position: 8px 7px; 
          padding: 0 0 0 30px; 
          box-sizing: border-box;
          font-size: 14px;
        }
      }
    }
    .btns{
      .el-button{width: 110px; margin-right: 20px;}
    }
  }
  .list{ 
    .top{ border-bottom: 1px solid $c-border; padding-bottom: 20px;
      .left{width: 350px; height: 40px; 
        .new-title{ 
          p{font-size: 20px;  color: $c-2; font-weight: bold; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
          .txt{font-size: 14px; color: $c-9;}
        }
        .name{ margin-left: 20px;
          p{font-size: 20px;  color: $c-2; font-weight: bold;}
          .txt{font-size: 14px; color: $c-9;}
        }
      }
      .right{  
        .name{margin-right: 20px;
            p{font-size: 20px;  color: $c-2; font-weight: bold;}
            .txt{font-size: 14px; color: $c-9;}
          }
        .status{
          p{font-size: 20px;  color: $c-primary; font-weight: bold;}
          .success{color: $c-success;}
          .gray{color: $c-9;}
          .red{color:$c-danger;}
          
          .txt{font-size: 14px; color: $c-9;}
        }
      }
    }
    .content{ margin-top: 20px; 
        .time{
          span{font-size: 14px; color: $c-9; }
          .con{font-size: 14px; color: $c-2; margin-left: 10px; font-weight: 400;}
        }
    }
  }
  // 详情
  /deep/ #el-drawer__title{ background:$c-title; padding: 10px;color: $c-white;}
  /deep/ .el-drawer__header{ margin-bottom: 0px;}
  .drawer{ padding: 0px 20px;
    .title{height: 84px;  background: #F5F7F9; margin-top: 20px; padding: 10px 20px 10px 20px;
      .new-title{ margin-right: 15px;
        p{font-size: 20px;  color: $c-2; font-weight: bold; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
        .txt{font-size: 14px; color: $c-9;}
      }
      .name{  margin-right: 15px;
        p{font-size: 20px;  color: $c-2; font-weight: bold;}
        .txt{font-size: 14px; color: $c-9;}
      }
      .name-time{  margin-right: 15px;
        p{font-size: 14px; height: 36px; line-height: 36px; color: $c-2; font-weight: bold;}
        .txt{font-size: 14px; color: $c-9;}
      }
      .status{ 
        p{font-size: 20px;  color:$c-primary; font-weight: bold;}
        .success{color: $c-success;}
        .gray{color: $c-9;}
        .red{color:$c-danger;}
        .txt{font-size: 14px; color: $c-9;}}
    }
    .content{ margin: 20px 0px;
      .puplish{
        .puplish-name{color: $c-2; margin-bottom: 10px; font-weight:bold;}
        .puplish-con{ 
          .img{width:44px; height: 44px; overflow: hidden; border-radius: 50%; margin-right: 10px;
            img{width:44px; height: 44px;}
          }
          .name1{  width: 180px;
            p{font-size: 16px;  color: $c-2; font-weight: bold;
               img{margin: 6px 0px 0px 5px;}
            }
            .txt{font-size: 12px; color: $c-9;
              .line{margin: 0px 5px;}
            }
          }
          .name{  width: 160px;
            p{font-size: 16px;  color: $c-2; font-weight: bold;
               img{margin: 6px 0px 0px 5px;}
            }
            .txt{font-size: 12px; color: $c-9;
              .line{margin: 0px 5px;}
            }
          }
        }
        .call-off{background: #F5F7F9; margin-top: 20px; padding: 10px 20px 10px 20px;
          .time{  margin-bottom: 10px;
            span{margin-right: 10px; font-size: 14px; color: $c-2;}
            .time-box{font-size: 14px;  color: $c-9; }
          }
          .cause{
            .cause-box{font-size: 14px;  color: $c-9; width: 64px;}
            .cause-txt{font-size: 14px;  color: $c-2; width: 450px;}
          }
        }
        .path-box{ margin: 20px 0px;
          p{color: &c-2; font-weight:bold; margin: 20px  0px; font-weight: bold;}
          .path-con{
            .icon-left{width: 10px; height: 90px;  margin: 6px 20px 0px 0px;
              .green{width: 10px; height: 10px; background: $c-success; border-radius: 50%;}
              .dian{margin: 12px 0px;
                img{margin: 3px;}
              }
              .red{width: 10px; height: 10px; background: red; border-radius: 50%;}
            }
            .path-right{ width:500px;
              .start{ font-size: 14px; margin-bottom: 10px; height: 50px;}
              .finish{ font-size: 14px;}
            }
          }
        }
        .status-con{ margin-top: 10px;
          p{color: $c-2; font-weight:bold; margin: 20px  0px;}
          .text{font-size: 14px; font-weight:400;}
        }
        .order-load{ margin-top: 20px;
          .puplish-name{color: $c-2; margin-bottom: 10px; font-weight:bold;}
          .puplish-con{ 
            .img{width:44px; height: 44px;  border-radius: 50%; margin-right: 10px;}
            .order-box{ 
              .name1{  width: 180px; margin-bottom: 10px;
                p{font-size: 16px;  color: $c-2;
                   img{margin: 6px 0px 0px 5px;}
                }
                .txt{font-size: 12px; color: $c-9;
                  .line{margin: 0px 5px;}
                }
              }
              .name{  width: 160px; margin-bottom: 10px;
                p{font-size: 16px;  color: $c-2;
                   img{margin: 6px 0px 0px 5px;}
                }
                .txt{font-size: 14px; color: $c-9;
                  .line{margin: 0px 5px;}
                }
              }
              .path-ing{
                .time{ margin-right: 20px; 
                  span{margin-right: 10px; font-size: 14px; color: $c-2;}
                  .time-tex{font-size: 14px;  color: $c-9;}
                }
              }
            }
          }
        }
      }
    }  
  } 
}
</style>